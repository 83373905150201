import Slider from './Slider/Slider'
import s from './Galeria.module.css'


function Galeria() {
    return (
        <div className={s.galeria}>
            <div className={s.bgimg}>
                <div className={s.content}>
                    <h1 className={s.title}>Galeria</h1>
                    <Slider />
                    <p className={s.descr}>W naszym domu rekolekcyjnym można korzystać z:</p>
                    <p className={s.descr}>Kaplicy</p>
                    <p className={s.descr}>Pokoju wypoczynkowego</p>
                    <p className={s.descr}>Pzyjemnej kawiarenki</p>
                    <p className={s.descr}>Auli i dwóch sal konferencyjnych</p>
                    <p className={s.descr}>Refektarza</p>
                    <p className={s.descr}>Dużego parkingu</p>
                    <p className={s.descr}>Terenu rekreacynego</p>
                </div>
            </div>
        </div>
    )
}

export default Galeria