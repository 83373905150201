import s from './Plan.module.css'

function Plan() {
    return (
        <div className={s.plan}>
            <div className={s.bgimg}>
                <div className={s.content}>
                    <h1 className={s.title}>Regulamin</h1>
                    <ol className={s.descr}>
                        <li>Godziny ciszy: Od 22:00 do 7:00.</li>
                        <li>Zakaz palenia i używania narkotyków na terenie całej posesji.</li>
                        <li>Nie korzystamy z napoi alkoholowych.</li>
                        <li>Zachowujemy porządek z szacunku do siebie i do innych gości.</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default Plan