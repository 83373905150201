
import s from './Rooms.module.css'

function Rooms() {
    return (
        <div className={s.rooms}>
            <div className={s.bgimg}>
                <div className={s.content}>
                    <h1 className={s.title}>Pokoje</h1>
                    <p className={s.descr}>
                        Dom wyposażony jest w:
                        
                        <p>- 81 łóżek w 37 pokojach 
                        <p>3 jednoosobowe z łazienką</p>
                        <p>11 dwuosobowych z łazienką</p>
                        <p>9 trzyosobowych z łazienką</p>
                        <p>studio: 2 pokoje jednoosobowe ze wspólną łazienką</p>
                        <p>studio: 2 pokoje dwuosobowe ze wspólną łazienką</p>
                        <p>studio: 2 pokoje dwuosobowe ze wspólną łazienką</p>
                        <p>studio: jeden pokój dwuosobowy i drugi trzyosobowy ze wspólną łazienką</p>
                        <p>studio: jeden pokój dwuosobowy i drugi trzyosobowy ze wspólną łazienką</p>
                        <p>studio: jeden pokój dwuosobowy i drugi trzyosobowy ze wspólną łazienką</p>
                        </p>
                        
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Rooms