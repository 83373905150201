import s from './Kitchen.module.css'

function Kitchen() {
    return (
        <div className={s.kitchen}>
            <div className={s.bgimg}>
                <div className={s.content}>
                    <h1 className={s.title}>Kuchnia i Refektarz</h1>
                    <div className={s.descr}>
                        <p>W Domu Rekolekcyjnym funkcjonuje kuchnia.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kitchen